/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment, { min } from "moment";

function getColor(color, alpha) {
    let _ = document.createElement('div');
    _.style.color = color;
    _.style.display = 'none';
    document.body.appendChild(_);
    const rgb = window.getComputedStyle(_).color.replace("rgb(", "").replace(")", "").split(", ");
    document.body.removeChild(_);
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
}

function trailing_earnings(row, minSplitFactor) {
    if (!row) return 0;

    minSplitFactor = minSplitFactor || 1;

    const q0 = row["q0"];
    const q1 = row["q_1"];
    const q2 = row["q_2"];
    const q3 = row["q_3"];
    const split_factor = row["price_1"] / minSplitFactor;
    return (q0 + q1 + q2 + q3) / split_factor;
}
function one_year_forward_earnings(row, minSplitFactor) {
    if (!row) return 0;

    minSplitFactor = minSplitFactor || 1;

    const today_est_revision = row["today_est_revision"];
    const split_factor = row["price_1"] / minSplitFactor;
    const val = today_est_revision / split_factor;
    return val == 0 ? null : val;
}
function two_year_forward_earnings(row, minSplitFactor) {
    if (!row) return 0;

    minSplitFactor = minSplitFactor || 1;

    const estimate_best_eps_lt = row["estimate_best_eps_lt"];
    const split_factor = row["price_1"] / minSplitFactor;
    const val = estimate_best_eps_lt / split_factor;
    return val == 0 ? null : val;
}
function price(row, minSplitFactor) {
    if (!row) return 0;

    minSplitFactor = minSplitFactor || 1;

    const price = row["price_2"];
    const split_factor = row["price_1"] / minSplitFactor;
    const val = price / split_factor;
    return val == 0 ? null : val;
}
function dividend(row, minSplitFactor) {
    if (!row) return 0;

    minSplitFactor = minSplitFactor || 1;

    const _yield = row["yield"];
    const split_factor = row["price_1"] / minSplitFactor;
    const price2 = row["price_2"];
    const val = _yield * price2 / split_factor;
    return val == 0 ? null : val;
}

function net_debt(row) {
    if (!row) return 0;

    const cash = row["cash"];
    const debt = row["debt"];
    return debt - cash;
}
function net_debt_pos(row) {
    if (!row) return 0;

    const val = net_debt(row);
    if (val > 0) return val;
    else return null;
}
function net_debt_neg(row) {
    if (!row) return 0;

    const val = net_debt(row);
    if (val <= 0) return Math.abs(val);
    else return null;
}
function forward_return(row, arr) {
    if (!row) return 0;

    const index = arr.indexOf(row) + 1;
    if (index + 6 >= arr.length) return null;

    const prod = arr.slice(index, index + 12).reduce((acc, r) => acc * r["estimate_years"], 1) - 1;
    return prod;
}
function average_price_behind(row, arr, months, minSplitFactor) {
    if (!row) return 0;
    if (!months) months = 12;
    const rowDate = moment(row["related_date"], "YYYY-MM-DD");
    const monthsAgo = rowDate.clone().subtract(months, "months");

    const filtered = arr.filter(d => monthsAgo <= moment(d["related_date"], "YYYY-MM-DD") && moment(d["related_date"], "YYYY-MM-DD") <= rowDate);
    const avg = filtered.reduce((acc, d) => acc + price(d, minSplitFactor), 0) / filtered.length;
    return avg;
}
function average_price_ahead(row, arr, months) {
    if (!row) return 0;
    if (!months) months = 12;
    const rowDate = moment(row["related_date"], "YYYY-MM-DD");
    const monthsAhead = rowDate.clone().add(months, "months");

    const filtered = arr.filter(d => rowDate <= moment(d["related_date"], "YYYY-MM-DD") && moment(d["related_date"], "YYYY-MM-DD") <= monthsAhead);
    const avg = filtered.reduce((acc, d) => acc + price(d), 0) / filtered.length;
    return avg;
}
function abs_reversion(row) {
    return row["abs_reversion"];
}
function growth_reversion(row) {
    return row["growth_reversion"];
}
function pe_reversion(row) {
    if (!row) return 0;

    const avg_fwd_p_by_e_relative_to_spx = row["ai"];
    const fwd_p_by_e_relative_to_spx = row["fwd_p_by_e_relative_to_spx"];
    const val = (avg_fwd_p_by_e_relative_to_spx / fwd_p_by_e_relative_to_spx) - 1
    return val == 0 ? null : val;
}
// function ps_reversion(row) {
//     if (!row) return 0;

//     const sales = row["price_by_sales"];
//     const median_sales = row["median_of_price_by_sales"];
//     const val = (median_sales / sales) - 1
//     return val == 0 ? null : val;
// }

function total_return(row) {
    const val = row["estimate_years"];
    return val == 0 ? null : val;
}

//function compound_by_key(arr, numYear, prevNumYear, key) {
//    const isProp = typeof key === "string";

//    const year = moment().subtract(numYear, "years").year();
//    const monthly_returns = arr.filter(row => moment(row["related_date"], "YYYY-MM-DD").year() >= year).reduce((acc, row) => acc * (isProp ? row[key] : key(row)), 1);
//    const compound_rate = 1 / numYear;
//    const result = Math.pow(monthly_returns, compound_rate);

//    if (prevNumYear) {
//        const prevYear = moment().subtract(prevNumYear, "years").year();
//        const monthly_returns_prev = arr.filter(row => moment(row["related_date"], "YYYY-MM-DD").year() >= prevYear).reduce((acc, row) => acc * (isProp ? row[key] : key(row)), 1);
//        const compound_rate_prev = 1 / prevNumYear;
//        const result_prev = Math.pow(monthly_returns_prev, compound_rate_prev);

//        return (result - result_prev) / result_prev;
//    }

//    return result;
//}
//function compounded_total_return(arr, numYear, prevNumYear) {
//    return compound_by_key(arr, numYear, prevNumYear, "estimate_years");
//}
function change(n_1, n_0) {
    if (!n_0) n_0 = 1;
    return parseFloat((n_1 - n_0) / n_0);
}

export default {
    getColor,
    trailing_earnings,
    one_year_forward_earnings,
    two_year_forward_earnings,
    price,
    dividend,
    net_debt,
    net_debt_pos,
    net_debt_neg,
    forward_return,
    abs_reversion,
    growth_reversion,
    pe_reversion,
    //ps_reversion,
    total_return,
    average_price_behind,
    average_price_ahead,
    change
}
